var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "maintenance-table" }, [
    _c("table", [
      _c("thead", [
        _c(
          "tr",
          [
            _c("th", [_vm._v("序号")]),
            _c("th", [_vm._v("系统")]),
            _c("th", [_vm._v("项目描述")]),
            _c("th", [_vm._v("周期")]),
            _c("th", [_vm._v("内容概述")]),
            _vm._l(_vm.months, function(month) {
              return _c("th", { key: month }, [_vm._v(_vm._s(month))])
            })
          ],
          2
        )
      ]),
      _c(
        "tbody",
        _vm._l(_vm.maintenanceItems, function(item, index) {
          return _c(
            "tr",
            { key: index },
            [
              _c("td", [_vm._v(_vm._s(index + 1))]),
              _c("td", [_vm._v(_vm._s(item.System))]),
              _c("td", [_vm._v(_vm._s(item.Detail))]),
              _c("td", [_vm._v(_vm._s(item.Circle))]),
              _c("td", [_vm._v(_vm._s(item.Description))]),
              _vm._l(_vm.months, function(month) {
                return _c("td", [
                  _c("input", {
                    attrs: { type: "checkbox", disabled: "" },
                    domProps: { checked: item[month] }
                  })
                ])
              })
            ],
            2
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }