<template>
    <div class="maintenance-table">
        <table>
            <thead>
                <tr>
                    <th>序号</th>
                    <th>系统</th>
                    <th>项目描述</th>
                    <th>周期</th>
                    <th>内容概述</th>
                    <th v-for="month in months" :key="month">{{ month }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in maintenanceItems" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.System }}</td>
                    <td>{{ item.Detail }}</td>
                    <td>{{ item.Circle }}</td>
                    <td>{{ item.Description }}</td>
                    <td v-for="month in months">
                        <input type="checkbox" :checked="item[month]" disabled />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            maintenanceItems: [
                {
                    "System": "高低压系统",
                    "Detail": "高低压-单路市电中断+高压/低压联络故障",
                    "Circle": "年度",
                    "Description": "模拟单路市电相断电后高压/低压联络同时故障的场景，判断监控系统本身及DC一线值班团队事件定位、影响评估，应急处置，通报汇报，供应商联动",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": true,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": false,
                    "12月": false
                },
                {
                    "System": "高低压系统",
                    "Detail": "高低压供电-双路市电先后中断+油机自启故障",
                    "Circle": "年度",
                    "Description": "制造双路市电中断后油机自启失败同时故障情景，判断监控系统本身及DC一线值班团队事件定位、影响评估，应急处置，通报汇报，供应商联动",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": false,
                    "6月": true,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": false,
                    "12月": false
                },
                {
                    "System": "高低压系统",
                    "Detail": "高低压供电-市电闪断",
                    "Circle": "年度",
                    "Description": "模拟市电闪断的故障情景，判断监控系统本身及DC一线值班团队事件定位、影响评估，应急处置，通报汇报，供应商联动",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": false,
                    "6月": false,
                    "7月": true,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": false,
                    "12月": false
                },
                {
                    "System": "暖通系统",
                    "Detail": "中央空调-中央空调主机故障",
                    "Circle": "年度",
                    "Description": "制造中央空调主机故障情景，判断监控系统本  身及DC一线值班团队事件定位、影响评估，应急处置，通报汇报，供应商联动",
                    "1月": true,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": false,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": false,
                    "12月": false
                },
                {
                    "System": "暖通系统",
                    "Detail": "精密空调-内部渗水故障",
                    "Circle": "年度",
                    "Description": "制造精密空调内部渗水同时门禁系统故障，判断监控系统本身及DC一线值班团队事件定位、影响评估，应急处置，通报汇报，供应商联动",
                    "1月": false,
                    "2月": false,
                    "3月": true,
                    "4月": false,
                    "5月": false,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": false,
                    "12月": false
                },
                {
                    "System": "暖通系统",
                    "Detail": "中央空调-电缆桥架着火",
                    "Circle": "年度",
                    "Description": "模拟中央空调电缆桥架着火，判断监控系统本身及DC 一线值班团队事件定位、影响评估，应急处置，通报汇报，供应商联动",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": false,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": true,
                    "10月": false,
                    "11月": false,
                    "12月": false
                },
                {
                    "System": "UPS系统",
                    "Detail": "UPS单机故障",
                    "Circle": "年度",
                    "Description": "模拟一台UPS故障，需隔离故障UPS，判断监控系统本身及DC一线值班团队事件定位、影响评估，应急处置，通报汇报，供应 商联动",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": false,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": true,
                    "11月": false,
                    "12月": false
                },
                {
                    "System": "UPS系统",
                    "Detail": "UPS系统故障",
                    "Circle": "年度",
                    "Description": "模拟UPS系统故障，维修时需保证末端设备供电正常，判断监控系统本身及DC一线值班团队事件定位、影响评估，应急处置，通报汇报，供应 商联动",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": false,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": false,
                    "12月": true
                },
                {
                    "System": "机房系统",
                    "Detail": "客户机柜PDU单路掉电故障",
                    "Circle": "年度",
                    "Description": "模拟一个机柜内一台服务器主路故障导致PDU跳闸，需隔离该单台设备，以便检修及更换，判断监控系统本身及DC一线值班团队事件定位、影响评估，应急处置，通报汇报，供应 商联动",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": true,
                    "5月": false,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": false,
                    "12月": false
                },
                {
                    "System": "环境安全",
                    "Detail": "消防安全演练",
                    "Circle": "半年度",
                    "Description": "灭火、联动、警戒、疏散、逃生",
                    "1月": false,
                    "2月": false,
                    "3月": false,
                    "4月": false,
                    "5月": true,
                    "6月": false,
                    "7月": false,
                    "8月": false,
                    "9月": false,
                    "10月": false,
                    "11月": true,
                    "12月": false
                }
            ]
        };
    }
};
</script>

<style scoped>
.maintenance-table {
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}
</style>